import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../_metronic/helpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {TranslationsQueryResponse} from './_models'
import {ITranslationObject} from "../../../websites/core/_models";

const API_URL = process.env.REACT_APP_API_PATH

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}/`

const getTranslations = (route: string, query: string): Promise<TranslationsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}${route}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data
    })
}

const getTranslationById = (route: string, id: ID) => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}${route}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => console.log(err))
}

const fetchTranslationsButton = (route:string) => {
  return axios
    .post(`${BASE_URL}${route}`, {headers: {'Content-Type': 'application/json'}})
    .then((data) => {
      return data.data
    })
}

const editTranslation = (
  key: {keyName: string; translations: {locale: string; value: string}[]},
  id: ID,
  route: string,
) => {
  return axios.put(`${BASE_URL}${route}/${id}`, key).then((response) => {
    return response.data
  })
}

export {getTranslations, fetchTranslationsButton, getTranslationById, editTranslation}
