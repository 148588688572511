import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {ICollection} from '../../../../_models'
import {CollectionActionsCell} from './cells/CollectionActionsCell'
import {CollectionTitleCell} from './cells/CollectionTitleCell'

const collectionsColumns: ReadonlyArray<Column<ICollection>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'BLOG_COLUMNS_TITLE'} className='min-w-125px' />
    ),
    id: 'title',
    Cell: ({...props}) => <CollectionTitleCell collection={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'COLUMNS_ACTIONS'} className='min-w-120px text-end' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CollectionActionsCell collection={props.data[props.row.index]} />,
  },
]

export {collectionsColumns}
