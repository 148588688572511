import {Form, Formik, FormikValues} from 'formik'
import {useState} from 'react'
import {useQueryClient} from 'react-query'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../../_metronic/helpers'
import {IWebsite} from '../../../../websites/core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {LinksArray} from '../../../../../modules/custom/form-elements/LinksArray'

import {IEditStore, IEditStoreForm} from '../../../core/_models'
import {editStore, uploadImage} from '../../../core/_requests'
import {ModalNotification} from '../../../../../modules/custom/modals/ModalNotification'

interface Props {
  store: IEditStore
  invalidateStore: () => void
}
const StoreLinks = ({store, invalidateStore}: Props) => {
  const {urls = [''], link = '', slug = ''} = store

  const initialValues: Partial<IEditStoreForm> = {
    urls,
    link,
    slug,
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<IEditStoreForm>, actions: FormikValues) => {
    const {urls, slug, link} = values
    setLoading(true)

    try {
      const dataToSend: Partial<IEditStore> = {
        slug,
        link,
        urls,
      }

      await editStore(dataToSend, store.id)

      invalidateStore()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='form-links-store'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label d-flex fw-bold fs-6'>
                  <span className='me-2'>Ідентифікатори</span>
                  {/* <FormattedMessage id='TRACKS.COMPONENTS.TrackInvoiceLinks.LABEL' /> */}

                  <ModalNotification
                    svg='/media/icons/duotune/general/gen046.svg'
                    addSvgClass='svg-icon-3'
                    addClass='ModalNotification__position'
                    children={
                      <div className='fs-7 fw-normal text-black'>
                        Вкажіть URL, по якому система автоматично ідентифікує приналежність
                        вказаного клієнтом посилання на товар до даного магазину.
                      </div>
                    }
                  />
                </label>
                <div className='col-lg-8 d-flex align-items-center'>
                  <div className='row w-75'>
                    <LinksArray
                      withTitle={false}
                      inputName='urls'
                      currentLinks={values?.urls || ['']}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Частина URL</label>

                <div className='col-lg-8'>
                  <InputTemplate inputName={'slug'} required={true} type='text' />
                </div>
              </div>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Посилання на магазин</label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='link' required={true} type='text' />
                </div>
              </div>{' '}
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default StoreLinks
