import {Column} from 'react-table'
import {OrderCustomHeader} from './OrderCustomHeader'
import {IOrder} from '../../../core/_models/_orders-models'
import {OrderItemsInfoCell} from './cells/OrderItemsInfoCell'
import {OrderNumberCell} from './cells/OrderNumberCell'
import {OrderActionsCell} from './cells/OrderActionsCell'
import {OrderUserCell} from './cells/OrderUserCell'
import {OrderTracksCell} from './cells/OrderTracksCell'
import {OrderTypeCell} from './cells/OrderTypeCell'
import {OrderStatusCell} from "./cells/OrderStatusCell";

const ordersColumns: ReadonlyArray<Column<IOrder>> = [
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={'ORDERS_COLUMNS_ORDER_NUMBER'}
        className='min-w-50px'
      />
    ),
    id: 'orderNumber',
    Cell: ({...props}) => <OrderNumberCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={'ORDERS_COLUMNS_ORDER_TRACKS'}
        className='min-w-50px'
      />
    ),
    id: 'tracks',
    Cell: ({...props}) => <OrderTracksCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={'ORDERS_COLUMNS_ORDER_TYPE'}
        className='min-w-150px'
      />
    ),
    id: 'type',
    Cell: ({...props}) => <OrderTypeCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={'TRACKS_COLUMNS_CONTENT'}
        className='min-w-150px'
      />
    ),
    id: 'items',
    Cell: ({...props}) => <OrderItemsInfoCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OrderCustomHeader tableProps={props} title={'TRACKS_COLUMNS_USER'} className='min-w-125px' />
    ),
    id: 'user',
    Cell: ({...props}) => <OrderUserCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OrderCustomHeader tableProps={props} title={'BUYOUTS_COLUMNS_STATUS'} className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <OrderStatusCell order={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-end'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <OrderActionsCell order={props.data[props.row.index]} />,
  },
]

export {ordersColumns}
