import {useEffect, useMemo, useState} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  stringifyRequestQuery,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../_metronic/helpers'
import {TranslationsTable} from './table/TranslationsTable'
import {TranslationsListHeader} from './components/header/TranslationsListHeader'
import {FormattedMessage} from 'react-intl'
import {fetchTranslationsButton} from '../core/_requests'
import {useTranslation} from "../TranslationsPage";

const TranslationsList = () => {
  const {name, sync, queryN} = useTranslation()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const {response} = useQueryResponse()

  const {updateState} = useQueryRequest()

  const openAddModal = () => {
    setItemIdForUpdate(null)
  }

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(queryN)

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(queryN),
      JSON.stringify(savedFilter)
    )
  }, [])

  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>
           <FormattedMessage id={name} defaultMessage={name} />{' '}
        </h3>
        {sync &&
          <button
              type='button'
              className='btn btn-primary mb-5'
              onClick={() => fetchTranslationsButton(sync)}
          >
              <FormattedMessage id='TRANSLATION.LIST.SYNC_BTN' defaultMessage='Syncronize keys'/>
          </button>
        }
      </div>
      <KTCard>
        <TranslationsListHeader/>
        <TranslationsTable/>
      </KTCard>
    </>
  )
}

const TranslationsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
    <ListViewProvider>
        <TranslationsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TranslationsListWrapper}
