import {FieldArray, Form, Formik} from 'formik'
import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS, KTSVG} from '../../../../../../_metronic/helpers'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {CheckboxSwitcher} from '../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {
  ICreateWarehouse,
  ICreateWarehouseForm,
  IWarehouse,
  TWarehouseTranslations,
} from '../../../core/_models'
import {updateWarehouse} from '../../../core/_requests'

type TProps = {
  warehouse: IWarehouse
  setCurrWarehouse: Dispatch<SetStateAction<IWarehouse | undefined>>
  invalidateWarehouse: () => void
  secondary?: boolean
}
const WarehouseAddress = ({
  warehouse,
  setCurrWarehouse,
  invalidateWarehouse,
  secondary = false,
}: TProps) => {
  useEffect(() => {
    invalidateWarehouse()
  }, [])
  const {locale = 'uk'} = useParams()

  const hidden =
    secondary === true
      ? warehouse.secondaryAddressHidden === true
        ? 1
        : 0
      : warehouse.addressHidden === true
      ? 1
      : 0
  const initialValues: ICreateWarehouseForm = {
    ...warehouse,
    hidden,
    countryConfig: {...warehouse.countryConfig, value: '', label: ''},
    name:
      secondary === true
        ? warehouse.translations[locale]?.secondaryAddressName || ''
        : warehouse.translations[locale]?.addressName || '',
    addressData:
      secondary === true
        ? warehouse?.translations[locale]?.secondaryAddressData ||
          warehouse.countryConfig.locationAddressFields.map((item) => '')
        : warehouse.translations[locale]?.addressData ||
          warehouse.countryConfig.locationAddressFields.map((item) => ''),
  }
  const intl = useIntl()

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: ICreateWarehouseForm) => {

    setLoading(true)
    const {addressData, name, hidden} = values

    let translations: TWarehouseTranslations = {
      ...warehouse?.translations,
      [locale]: {...warehouse?.translations[locale]},
    }

    if (secondary === true) {
      translations[locale].secondaryAddressName = name
      translations[locale].secondaryAddressData = addressData.every((item) => !item)
        ? []
        : addressData
    } else {
      translations[locale].addressName = name
      translations[locale].addressData = addressData.every((item) => !item) ? [] : addressData
    }
    const payload: Partial<ICreateWarehouse> = {
      translations,
    }

    if (secondary === true) {
      payload.secondaryAddressHidden = hidden === 1 ? true : false
    } else {
      payload.addressHidden = hidden === 1 ? true : false
    }

    try {
      const response = await updateWarehouse(warehouse.id, payload)


      if (response) {
        setCurrWarehouse(response)
        setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  const varsArray = [
    {value: '%externalId%', label: 'ID клієнта'},
    {value: '%firstName%', label: "Ім'я клієнта"},
    {value: '%lastName%', label: 'Прізвище клієнта'},
    {value: '%patronymic%', label: 'По-батькові клієнта'},
  ]

  const [show, setShow] = useState(false)
  const varRef = useRef(null)
  const [copied, setCopied] = useState<string[]>([])

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      {warehouse ? (
        <>
          <div className='card-header d-flex flex-gutter'>
            {varsArray.map((item, index) => (
              <div className='position-relative' key={index}>
                <div className='d-flex align-items-center'>
                  <span ref={varRef} className='me-2'>
                    {item.value}
                  </span>
                  <CopyToClipboard
                    text={item.value}
                    onCopy={() => {
                      setCopied([item.value])

                      setShow(true)
                      setTimeout(() => {
                        setShow(false)
                      }, 1000)
                    }}
                  >
                    <button type='button' className='highlight-copy btn btn-active-color-dark'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen054.svg'
                        className={`svg-icon-3 ${
                          copied.includes(item.value) ? 'svg-icon-dark' : 'svg-icon-gray-300'
                        }`}
                      />
                    </button>
                  </CopyToClipboard>{' '}
                  <div
                    className={`p-1 position-absolute bottom-100 StatusBar__status StatusBar_active ${
                      show === true && item.value === copied[0] ? 'd-block' : 'd-none'
                    }`}
                  >
                    {intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})}{' '}
                  </div>
                </div>
                <p className='fs-8 text-muted'>{item.label}</p>
              </div>
            ))}{' '}
          </div>
          <Formik
            // validationSchema={() => {}}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({values, setFieldValue, errors}) => (
              <Form noValidate className='form'>
                {/* {JSON.stringify(values)} */}
                {/*  {JSON.stringify(errors)} */}
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Відображення </label>
                    <div className='col-lg-8'>
                      <div className='row flex-nowrap'>
                        <div className='col-lg-6 fv-row me-4'>
                          <CheckboxSwitcher
                            inputName='hidden'
                            firstOption={intl.formatMessage({
                              id: 'WAREHOUSE.PAGE.ADDRESS.SHOW',
                            })}
                            secondOption={intl.formatMessage({
                              id: 'WAREHOUSE.PAGE.ADDRESS.HIDE',
                            })}
                            value={+values.hidden}
                            setFieldValue={setFieldValue}
                          />
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва адреси</label>

                    <div className='col-lg-8'>
                      <InputTemplate inputName='name' required={true} type='text' />
                    </div>
                  </div>{' '}
                  <FieldArray
                    name='addressData'
                    render={() => (
                      <div className='d-flex flex-column flex-col-gutter'>
                        {warehouse.countryConfig.locationAddressFields.map((field, index) => (
                          <div className='row mb-6' key={field}>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>{field} </label>
                            <div className='col-lg-8'>
                              <div className='row flex-nowrap'>
                                <div className='col-lg-6 fv-row me-4'>
                                  <InputTemplate
                                    inputName={`addressData.${index}`}
                                    type='text'
                                    titleFontSize='text-dark'
                                  />
                                </div>{' '}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
                <CardFooter loading={loading} success={footerNotification?.success || false} />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}

export default WarehouseAddress
