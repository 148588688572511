import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {StoreMeta} from './tabs/meta/StoreMeta'
import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {StoreHeader} from './header/StoreHeader'
import {StoreNoResults} from './StoreNoResults'
import {IEditStore} from '../core/_models'
import {getStoreById} from '../core/_requests'
import StoreOverview from './tabs/overview/StoreOverview'
import {StoreText} from './content/StoreText'
import {StoreExtraFields} from "./content/StoreExtraFields";
import StoreLinks from './tabs/links/StoreLinks'
import StoreImages from './tabs/images/StoreImages'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {QUERIES} from '../../../../_metronic/helpers'
import {IWebsite} from '../../websites/core/_models'
import {ICompanyDetails} from "../../settings/core/_models";

interface Props {
  id: string | number
}

const StorePage = ({id}: Props) => {
  const {data: storeById} = useQuery(`store-${id}`, () => getStoreById(id))

  const [currStore, setCurrStore] = useState<IEditStore | undefined>(undefined)

  const queryClient = useQueryClient()

  const invalidateStore = () => {
    queryClient.invalidateQueries(`store-${id}`)
  }

  useEffect(() => {
    if (storeById) {
      setCurrStore(storeById)
    }
  }, [storeById])
  const companyData: ICompanyDetails | undefined = queryClient.getQueryData(QUERIES.COMPANY_DETAILS)
  const defaultLocale = companyData ? companyData.mainLocale : 'uk'
  return (
    <>
      {currStore && storeById ? (
        <Routes>
          <Route
            element={
              <>
                <StoreHeader store={currStore} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <StoreOverview store={currStore} invalidateStore={invalidateStore} />
                </>
              }
            />
            <Route
              path={`images/:locale/:id`}
              element={
                <>
                  <StoreImages store={currStore} invalidateStore={invalidateStore} />
                </>
              }
            />
            <Route
              path={`links/:locale/:id`}
              element={
                <>
                  <StoreLinks store={currStore} invalidateStore={invalidateStore} />
                </>
              }
            />
            <Route
              path={`extra/:locale/:id`}
              element={
                <>
                  <StoreExtraFields store={currStore} invalidateStore={invalidateStore} />
                </>
              }
            />
            <Route
              path={`text/:locale/:id`}
              element={
                <>
                  <StoreText store={currStore} invalidateStore={invalidateStore} />
                </>
              }
            />
            <Route
              path={`meta/:locale/:id`}
              element={
                <>
                  <StoreMeta store={currStore} invalidateStore={invalidateStore} />
                </>
              }
            />

            <Route path={`:id`} element={<StoreNoResults />} />

            <Route index element={<Navigate to={`overview/${defaultLocale}/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default StorePage
