import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'

const App = () => {
  let location = useLocation()

  const getTabNameFromLocation = (pathname: string) => {
    if (pathname === '/tracks') {
      return 'Треки'
    } else if (pathname === '/directions') {
      return 'Направления'
    } else if (pathname === '/dashboard') {
      return 'Основное'
    } else if (pathname === '/delivery-types') {
      return 'Типы доставки'
    } else if (pathname === '/websites') {
      return 'Сайты'
    } else if (pathname === '/tracks/accept') {
      return 'Приём трека'
    } else if (pathname === '/tracks/add') {
      return 'Создать трек'
    } else {
      console.log(pathname)
      return 'Доставка'
    }
  }

  useEffect(() => {
    document.title = `${getTabNameFromLocation(location.pathname)} | Crauzer`
  }, [location.pathname])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
