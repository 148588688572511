import {useRef, useEffect, ReactNode} from 'react'
import {Field, useFormikContext} from 'formik'

interface Props {
  type: string
  inputName: string
  placeholder?: string
  backgroundClass?: string
  autoFocus?: boolean
  inputFontSize?: string
  handleChange?: any
  handleBlur?: any
  disabled?: boolean
  prependedFieldImage?: ReactNode
}

export function NumberTextInput({
  type,
  inputName,
  placeholder,
  backgroundClass,
  autoFocus,
  inputFontSize,
  handleChange,
  handleBlur,
  disabled = false,
  prependedFieldImage,
}: Props) {
  const autoFocusRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autoFocus === true) {
      autoFocusRef.current?.focus()
    }
  }, [autoFocus])
  const {setFieldValue} = useFormikContext()

  const fieldElement = (
    <Field
      type={type}
      disabled={disabled}
      className={`form-control form-control-lg form-control-solid text-dark ${prependedFieldImage ? 'ps-20 pe-2' : ''} ${inputFontSize || ''} ${backgroundClass || ''}`}
      name={inputName}
      placeholder={placeholder || ''}
      innerRef={autoFocusRef}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(inputName, e.target.value)
        if (handleChange) {
          type === 'number' ? handleChange(+e.target.value) : handleChange(e.target.value)
        }
      }}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => (e.target.placeholder = '')}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        e.target.placeholder = placeholder || ''
        if (handleBlur) {
          handleBlur(e.target.value)
        }
      }}
    />
  )

  return prependedFieldImage ? <div className="position-relative">{prependedFieldImage}{fieldElement}</div> : fieldElement
}
