import {Form, Formik, FormikValues} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS, selectDebounce} from '../../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {ICollection, IEditCollection, IEditCollectionForm} from '../../../../_models'
import {editCollection} from '../../../core/_requests'
import CategoriesMultipleSelect from "../../../../../../modules/custom/form-elements/selects/CategorySelect";
import PartialPageLoader from "../../../../../../../_metronic/layout/core/PartialPageLoader";
import {ICategorySelectOption} from "../../../../../lists/categories/core/_models";
import {getCategories} from "../../../../../categories/stores/core/_requests";
import {getStores} from "../../../../../stores/core/_requests";

interface Props {
  collection: ICollection
  invalidateCollection: () => void
}
const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const CollectionOverview = ({collection, invalidateCollection}: Props) => {
  const intl = useIntl()
  const {locale = 'uk'} = useParams()

  const initialValues: IEditCollectionForm = {
    name: collection.translations[locale]?.name||'',
    fromCategory: collection.fromCategory?1:0,
    itemsCategory: {...collection.itemsCategory, value: collection.itemsCategory?.id || '', label: collection.itemsCategory?.name || ''},
    items:
      collection.items?.map((i) => ({
        value: i.id,
        label: i.name || '',
        image: i.logoUrl ? `${BASE_URL}${i.logoUrl}` : '/media',
      })) || [],
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: IEditCollectionForm, actions: FormikValues) => {
    const {name} = values
    setLoading(true)

    try {
      let translations = {
        ...collection?.translations,
        [locale]: {name},
      }

      const dataToSend: Partial<IEditCollection> = {
        translations,
        fromCategory: !!values.fromCategory,
        itemsCategory: values.fromCategory?{id: values.itemsCategory?.value}:null,
        items: values.fromCategory?[]:values?.items?.map((i) => {
          return {id: i.value}
        }),
      }

      await editCollection(dataToSend, collection.id)

      invalidateCollection()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
      actions.resetForm()
    }
  }

  const [categoriesResults, setCategoriesResults] = useState<ICategorySelectOption[] | undefined>([])
  const [entitiesResults, setEntitiesResults] = useState<ICategorySelectOption[] | undefined>([])

  const filterCategories = useCallback(
    selectDebounce((query: string, callback?: any) => {
      getCategories(`search=${query}`)
        .then((res) => {
          const reformedCategories = res.data?.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          // @ts-ignore
          setCategoriesResults(reformedCategories)
          if (callback) {
            callback(reformedCategories)
          }
        })
        .catch((err) => console.log(err))
    }, 500),
    [getCategories]
  )

  const filterEntities = useCallback(
    selectDebounce((query: string, callback?: any) => {
      getStores(`search=${query}`)
        .then((res) => {
          const reformedEntities = res.data?.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          // @ts-ignore
          setEntitiesResults(reformedEntities)
          if (callback) {
            callback(reformedEntities)
          }
        })
        .catch((err) => console.log(err))
    }, 500),
    [getStores]
  )

  useEffect(() => {
    filterCategories('')
  }, [filterCategories])

  useEffect(() => {
    filterEntities('')
  }, [filterEntities])

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      {categoriesResults && categoriesResults.length > 0 && entitiesResults && entitiesResults.length > 0 ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form' id='form-overview-blog'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='name' required={true} type='text'/>
                  </div>
                </div>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Тип</label>

                  <div className='col'>
                    <div className='row'>
                      <InputTemplate
                        fieldWidth='col'
                        inputName='fromCategory'
                        required={false}
                        type='radio'
                        value={1}
                        title={intl.formatMessage({
                          id: 'GENERAL.ASIDE_MENU.CONTENT_CATEGORY_TITLE',
                        })}
                        titleFontSize='fs-5 text-dark fw-bold'
                        checkboxProps={{
                          type: 'radio',
                          inputName: 'fromCategory',
                          setFieldValue: setFieldValue,
                          disabled: false,
                          checked: !!values.fromCategory,
                          value: 1,
                        }}
                      />
                      <InputTemplate
                        fieldWidth='col'
                        inputName='fromCategory'
                        required={false}
                        type='radio'
                        value={0}
                        title={intl.formatMessage({
                          id: 'WEBSITE_COLLECTIONS.TYPE.CUSTOM',
                        })}
                        titleFontSize='fs-5 text-dark fw-bold'
                        checkboxProps={{
                          type: 'radio',
                          inputName: 'fromCategory',
                          setFieldValue: setFieldValue,
                          disabled: false,
                          checked: !values.fromCategory,
                          value: 0,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className={`row my-7 align-items-center ${!values.fromCategory?'d-none':''}`}>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'><FormattedMessage id="GENERAL.ASIDE_MENU.CONTENT_CATEGORY_TITLE" /></label>
                  <div className='col-lg-8'>
                    <CategoriesMultipleSelect
                      categoriesAvailable={categoriesResults.map((i) => ({
                        image: i.imageUrl ? `${BASE_URL}${i.imageUrl}` : '/media',
                        label: i.name,
                        value: i.id,
                      }))}
                      inputName="itemsCategory"
                      values={values}
                      isMulti={false}
                    />
                  </div>
                </div>

                <div className={`row my-7 align-items-center ${values.fromCategory?'d-none':''}`}>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'><FormattedMessage id="GENERAL.ASIDE_MENU.WEBSITES_STORES_TITLE" /></label>
                  <div className='col-lg-8'>
                    <CategoriesMultipleSelect
                      categoriesAvailable={entitiesResults.map((i) => ({
                        image: i.logoUrl ? `${BASE_URL}${i.logoUrl}` : '/media',
                        label: i.name,
                        value: i.id,
                      }))}
                      inputName="items"
                      values={values}
                      isMulti={true}
                    />
                  </div>
                </div>
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false}/>
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader/>
      )}
    </div>
  )
}

export default CollectionOverview
