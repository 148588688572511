import {ErrorMessage} from 'formik'
import {selectDebounce} from '../../../../_metronic/helpers'
import CategoriesMultipleSelect from '../../../modules/custom/form-elements/selects/CategorySelect'
import {ICategorySelectOption} from '../../lists/categories/core/_models'
import {IDeliveryTypeForm} from '../core/_models'
import {useCallback, useEffect, useState} from "react";
import {getCategories} from '../../lists/categories/core/_requests'

interface IProps {
  values: IDeliveryTypeForm
}

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

const Categories = ({values}: IProps) => {
  const [categoriesResults, setCategoriesResults] = useState<ICategorySelectOption[] | undefined>(
    []
  )

  const filterCategories = useCallback(
    selectDebounce((query: string, callback?: any) => {
      getCategories(`search=${query}`)
        .then((res) => {
          const reformedCategories = res.data?.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          // @ts-ignore
          setCategoriesResults(reformedCategories)
          if (callback) {
            callback(reformedCategories)
          }
        })
        .catch((err) => console.log(err))
    }, 500),
    [getCategories]
  )

  useEffect(() => {
    filterCategories('')
  }, [filterCategories])

  return (
    <>
      {' '}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
          <span className='required'> Категорії</span>{' '}
        </label>{' '}
        {categoriesResults && categoriesResults.length > 0 && (
          <CategoriesMultipleSelect
            categoriesAvailable={categoriesResults.map((i) => ({
              image: i.logoUrl ? `${BASE_URL}${i.logoUrl}` : '/media',
              label: i.name,
              value: i.id,
            }))}
            values={values}
          />
        )}
      </div>
      <div className='text-danger'>
        <ErrorMessage name='categories' />
      </div>
    </>
  )
}

export default Categories
