import {InputTemplate} from '../../../form-elements/InputTemplate'
import {AddTrackForm, ITrackFormProd} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {CategoriesWrapper} from '../../../sections/categories/CategoriesWrapper'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {getCategories} from '../../../../../pages/lists/categories/core/_requests'
import {IAcceptWarehouse} from '../../../../../pages/tracks-accept/core/_models/_models'
import {useFormikContext} from 'formik'
import {ListViewContextProps} from '../../../../../../_metronic/helpers'
import {getStoreByProductUrl} from '../../../../../pages/stores/core/_requests'
import {IStore} from '../../../../../pages/stores/core/_models'
import {useState} from 'react'

interface Props {
  item: ITrackFormProd
  index: number
  arrayHelpers: any
  prods: ITrackFormProd[]
  setFieldValue: ISetFieldValue
  values: {prods: ITrackFormProd[]; location?: IAcceptWarehouse; store?: IStore}
  listView: ListViewContextProps
}

export function TrackGoodsItemBody({
  item,
  index,
  arrayHelpers,
  prods,
  setFieldValue,
  values,
  listView,
}: Props) {
  const intl = useIntl()
  const {itemIdForUpdate, currPage} = listView
  const [urlError, setUrlError] = useState('')
  const {errors} = useFormikContext<AddTrackForm>()
  const findStore = async (url: string) => {
    try {
      const res = await getStoreByProductUrl(url)

      if (res?.id && values.store?.id) {
        if (+values.store?.id !== +res?.id) {
          setUrlError(
            intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST.URL_DIFF_STORE'})
          )
        } else {
          setUrlError('')
        }
      } else if (res?.id && !values.store) {
        setFieldValue('store', res)
        setUrlError('')
      } else {
        setUrlError('')
      }
    } catch (error) {
      if (values.store?.id) {
        setUrlError(
          intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST.URL_DIFF_STORE'})
        )
      } else {
        setUrlError('')
      }
      console.log(error)
    }
  }

  return (
    <>
      {currPage === 'buyouts' && (
        <div className='d-flex flex-column fv-row w-100'>
          <InputTemplate
            inputName={`prods.${index}.url`}
            disabled={!!itemIdForUpdate}
            type='text'
            required={true}
            title={intl.formatMessage({id: 'BUYOUTS.FORM.GOODS_LINK'})}
            value={item.url}
            marginClass='mb-0'
            containerMarginBottom={false}
            handleChange={(e: string) => {
              const cleaned = e.replace(/\s/g, '')
              setFieldValue(`prods.${index}.url`, cleaned)
              findStore(cleaned)
            }}
            prependedFieldImage={
              values.store && (
                <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer position-absolute top-50 translate-middle-y ps-3'>
                  <img
                    alt=''
                    className='rounded-circle w-40px me-2 fit-content'
                    src={
                      `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${values.store?.logoUrl}` ||
                      '/media/icons/duotune/ecommerce/ecm002.svg'
                    }
                  />
                </div>
              )
            }
          />
          <div className='text-danger'>{urlError}</div>
        </div>
      )}
      <div className='d-flex flex-column fv-row w-100'>
        {(values?.location?.acceptType === 'full' || !values?.location?.acceptType) && (
          <CategoriesWrapper
            inputName={{
              category: `prods.${index}.category`,
              user_name: `prods.${index}.user_name`,
              model: `prods.${index}.model`,
            }}
            setFieldValue={setFieldValue}
            selectValues={{
              category: item.category || {value: '', label: ''},
            }}
            apiRequest={getCategories}
            disabled={!!itemIdForUpdate}
            values={item}
          />
        )}
      </div>
      <div className='d-flex flex-gutter w-100'>
        <InputTemplate
          inputName={`prods.${index}.qty`}
          fieldWidth='w-50'
          disabled={!!itemIdForUpdate}
          type='number'
          required={true}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.QTY'})}
          value={item.qty}
          handleChange={(val: number) => {
            //  if for item.cost till full api
            if (item.cost) {
              setFieldValue(`prods.${index}.total`, (val * +item.cost).toFixed())
            }
          }}
          numberWithControls={true}
        />

        {(values?.location?.acceptType === 'full' || !values?.location?.acceptType) && (
          <InputTemplate
            inputName={`prods.${index}.cost`}
            type='number'
            fieldWidth='w-50'
            required={true}
            disabled={!!itemIdForUpdate}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST'})}
            addSymbol={'$'}
            value={item.cost}
            handleChange={(val: number) => {
              //  if for item.qty till full api
              if (item.qty) {
                setFieldValue(`prods.${index}.total`, (val * +item.qty).toFixed())
              }
            }}
          />
        )}
      </div>{' '}
      <div className='d-flex flex-gutter w-100 align-items-center'>
        {/* {weightIsNeeded === true && (
          <InputTemplate
            inputName={`prods.${index}.weight`}
            type='number'
            fieldWidth='w-50'
            required={true}
            disabled={!!itemIdForUpdate}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.WEIGHT'})}
            addSymbol={'кг'}
            value={item.weight}
          />
        )}{' '} */}
        {(values?.location?.acceptType === 'full' || !values?.location?.acceptType) && (
          <InputTemplate
            inputName={`prods.${index}.total`}
            addSymbol={'$'}
            type='number'
            fieldWidth='w-50'
            required={true}
            disabled={!!itemIdForUpdate}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.TOTAL_COST'})}
            value={item.total}
            handleChange={(val: number) => {
              //  if for item.qty till full api
              if (item.qty) {
                setFieldValue(`prods.${index}.cost`, (val / +item.qty).toFixed(1))
              }
            }}
          />
        )}

        {currPage !== 'buyouts' && prods.length > 1 && !itemIdForUpdate && (
          <div className='d-flex justify-content-center w-50'>
            {' '}
            <button
              className='btn btn-sm btn-secondary d-flex align-items-center'
              type='button'
              onClick={() => arrayHelpers.remove(index)}
            >
              <FormattedMessage id='GENERAL.BUTTONS.DELETE' />
            </button>
          </div>
        )}
      </div>{' '}
      {currPage === 'buyouts' && (
        <div className='d-flex flex-gutter w-100'>
          {' '}
          <InputTemplate
            inputName={`prods.${index}.color`}
            type='text'
            disabled={!!itemIdForUpdate}
            title={intl.formatMessage({id: 'BUYOUTS.FORM.COLOR'})}
            // value={item.color}
          />
          <InputTemplate
            inputName={`prods.${index}.sku`}
            type='text'
            disabled={!!itemIdForUpdate}
            title={intl.formatMessage({id: 'BUYOUTS.FORM.SKU'})}
            // value={item.sku}
          />
        </div>
      )}
      {currPage === 'buyouts' && (
        <div className='d-flex flex-gutter w-100 align-items-center'>
          <InputTemplate
            inputName={`prods.${index}.size`}
            type='text'
            fieldWidth='w-50'
            disabled={!!itemIdForUpdate}
            title={intl.formatMessage({id: 'BUYOUTS.FORM.SIZE'})}
            // value={item.size}
          />{' '}
          {prods.length > 1 && !itemIdForUpdate && (
            <div className='d-flex justify-content-center w-50'>
              {' '}
              <button
                className='btn btn-sm btn-secondary d-flex align-items-center'
                type='button'
                onClick={() => arrayHelpers.remove(index)}
              >
                <FormattedMessage id='GENERAL.BUTTONS.DELETE' />
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}
