import { useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ICategory} from '../../../_models'
import {useQueryClient} from "react-query";
import {ICompanyDetails} from "../../../../settings/core/_models";
import {QUERIES} from "../../../../../../_metronic/helpers";

interface Props {
  category: ICategory
}

const CategoryHeaderLangSwitcher = ({category}: Props) => {
  const {locale} = useParams()
  const [activeTab, setActiveTab] = useState(locale)
  const navigate = useNavigate()

  const location = useLocation()
  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  const queryClient = useQueryClient()
  const companyData: ICompanyDetails | undefined = queryClient.getQueryData(QUERIES.COMPANY_DETAILS)
  const enabledLocales = companyData ? companyData.enabledLocales : []

  return (
    <ul className='nav'>
      {enabledLocales.map((key) => (
        <li className='nav-item' key={key}>
          <button
            type='button'
            onClick={() => {
              navigate(`/website-categories/stores/edit/${currTab}/${key}/${category.id}`)

              setActiveTab(key)
            }}
            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
              activeTab === key ? 'active' : ''
            } fw-bold px-4`}
          >
            {key}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default CategoryHeaderLangSwitcher
