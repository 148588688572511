/* eslint-disable jsx-a11y/anchor-is-valid */
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {IWebsite} from '../../../../../websites/core/_models'

import {IStore} from '../../../../core/_models'

type Props = {
  store: IStore
}

const StoreWebsitesCell = ({store}: Props) => {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const storeWebsiteIds = store.websites?.map(w => w.id)||[]

  const websites = websitesData?.filter((item) => storeWebsiteIds.includes(item.id))

  return (
    <div className='d-flex justify-content-start flex-column'>
      {websites && websites.length > 0 ? (
        websites.map((website)=> (
          <div key={website.id}>
            <span className='fw-semobold d-block fs-6 me-1'> {website?.name || ' - '}</span>

            <a
              className='fw-normal d-block fs-7'
              href={`${process.env.REACT_APP_HTTP}${website?.host}`}
              target='_blank'
              rel='noreferrer noopener'
            >
              {website?.host}
            </a>
          </div>
        ))
      ) : ' - '}
    </div>
  )
}

export {StoreWebsitesCell}
