import {FC, useEffect, useState} from 'react'
import {useI18n} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import enMessages from './messages/en.json'
import ruMessages from './messages/ru.json'
import ukMessages from './messages/uk.json'
import bsMessages from './messages/bs.json'
import {WithChildren} from '../helpers'
import {getDashboardTranslationData} from "../../app/modules/general/core/_requests";

const allMessages = {
  en: enMessages,
  uk: ukMessages,
  ru: ruMessages,
  bs: bsMessages,
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const {selectedLang: locale, owner} = useI18n()
  const [translationData, setTranslationData] = useState(null)
  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }
  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'none')
    }
  }

  const getTD = async () => {
    enableSplashScreen()
    await getDashboardTranslationData()
      .then(({data}) => {
        setTranslationData(data)
      })
      .catch((e) => {
        // @ts-ignore
        setTranslationData(allMessages[locale])
        console.log(e)
      })
      .finally(()=>{
        disableSplashScreen()
      })
  }

  useEffect(() => {
    if(owner) {
      getTD()
    }else{
      // @ts-ignore
      setTranslationData(allMessages[locale])
    }
  }, [locale, owner])

  return (
    <>
      {translationData && (
        <IntlProvider locale={locale} messages={translationData}
                      onError={(err)=>{
                        // console.warn(err.message)
                      }}>
          {children}
        </IntlProvider>
      )}
    </>
  )
}

export {I18nProvider}
