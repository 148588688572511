import {useEffect, useState} from 'react'
import {Formik, Form, FormikHelpers, FieldArray} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {useListView} from '../core/ListViewProvider'
import {ScrollTopComponent} from '../../../../_metronic/assets/ts/components'
import {CreateWarehouseModalHeader} from './CreateWarehouseModalHeader'
import {ICreateWarehouseForm, warehouseLocales} from '../core/_models'
import {addWarehouse} from '../core/_requests'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import ScrollToFieldError from './ScrollToFieldError'
import CountriesSelect from '../../../modules/custom/form-elements/selects/CountriesSelect'
import {createWarehouseSchema} from '../core/yup/warehouse'
import {ICountry} from '../../countries/countries-list/core/_models'
import {QUERIES} from '../../../../_metronic/helpers'
import {FloatingInputTemplate} from '../../../modules/custom/form-elements/FloatingInputTemplate'

const CreateWarehouseForm = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const countriesData: ICountry[] = queryClient.getQueryData(QUERIES.COUNTRIES_LIST) || []
  const {setItemIdForUpdate} = useListView()

  const {refetch} = useQueryResponse()

  const inits: ICreateWarehouseForm = {
    hidden: 0,
    countryConfig: {value: countriesData[0]?.id || '', label: countriesData[0]?.country || ''},
    name: '',
    addressData: [''],
  }

  const [initValues] = useState<ICreateWarehouseForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  //mutation
  const createWarehouseMutation = useMutation(addWarehouse, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      setItemIdForUpdate(undefined)

      ScrollTopComponent.goTop()
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (
    values: ICreateWarehouseForm,
    formikHelpers: FormikHelpers<ICreateWarehouseForm>
  ) => {
    const {countryConfig, addressData, name} = values
    const translations: Record<string, {addressData: string[]; addressName: string; name: string}> =
      {}

    warehouseLocales.forEach((locale) => {
      translations[locale] = {
        addressData,
        addressName: name,
        name,
      }
    })

    createWarehouseMutation.mutate({
      countryConfig: {id: countryConfig.value},
      addressHidden: false,
      secondaryAddressHidden: true,
      name,
      translations,
    })
    formikHelpers.resetForm()
  }

  useEffect(() => {
    document.body.style.overflow = ''
  }, [])

  return (
    <Formik
      validationSchema={createWarehouseSchema(intl)}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form h-100' noValidate id='kt_modal_create_warehouse_form'>
            <ScrollToFieldError />

            <CreateWarehouseModalHeader isLoading={isLoading} isValid={isValid} />
            <div
              className='scroll-y modal-body p-0 h-100'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='p-3 text-primary'>
                {/* {JSON.stringify(errors)}
                {JSON.stringify(values)} */}
                <div className='w-100'>
                  <div className='fv-row mb-5'>
                    <CountriesSelect />
                  </div>
                  <div className='fv-row mb-5'>
                    <InputTemplate
                      inputName='name'
                      type='text'
                      required={true}
                      title={'Назва'}
                      titleFontSize='fs-5 text-dark'
                    />
                  </div>
                  <div className='fv-row mb-5'>
                    {!values.countryConfig.value ||
                    (values.countryConfig.locationAddressFields &&
                      values.countryConfig.locationAddressFields?.length > 0) ? (
                      <FieldArray
                        name='addressData'
                        render={() => (
                          <div className='d-flex flex-column flex-col-gutter'>
                            {values.countryConfig.locationAddressFields?.map((field, index) => (
                              <FloatingInputTemplate
                                key={field}
                                title={field}
                                inputName={`addressData.${index}`}
                                required={true}
                                type='text'
                                placeholder={field}
                                titleFontSize='text-dark'
                              />
                            ))}
                          </div>
                        )}
                      />
                    ) : (
                      <p className='text-danger fs-6'>
                        Для створення складу спочатку додайте необхідні поля для адреси в
                        налаштуваннях обраної країни.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export {CreateWarehouseForm}
