import {Link, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {useTranslation} from "../../TranslationsPage";

export function TranslationHeaderNav() {
  const {locale, '*': currentTab} = useParams()
  const {defaultLocale, notes} = useTranslation()
  let curLocale = locale?locale:defaultLocale

  let tabs = [
    {value: `overview/${curLocale}`, label: 'Переклад'},
  ]
  if(notes)tabs.push(
    {value: 'comment', label: 'Загальне'}
  )
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {tabs.map((tab) => (
          <li className='nav-item' onClick={scrollToTabsStart} key={tab.value}>
            <Link
              className={
                `nav-link text-active-primary me-6 text-center ` +
                (currentTab === tab.value && 'active')
              }
              to={`../${tab.value}`}
            >
              {tab.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
