import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {QUERIES, WithChildren} from '../helpers'
import {useQuery} from "react-query";
import {getCompanyDetails} from "../../app/pages/settings/core/_requests";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: string
  setLanguage: (lang: string) => void
  locales: string[]
  owner: number|null
  setOwner: (owner: number) => void
}

const initialState = {
  selectedLang: 'uk',
  setLanguage: () => {},
  locales: ['uk', 'ru', 'en'],
  owner: null,
  setOwner: () => {},
}

function langIsSet() {
  return !!localStorage.getItem(I18N_CONFIG_KEY)
}

export function getConfig():Pick<Props, 'selectedLang'> {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return {selectedLang: initialState.selectedLang}
}

const I18nContext = createContext<Props>(initialState)

const useI18n = () => {
  return useContext(I18nContext)
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
  const {selectedLang} = getConfig()
  const [locale, setLocale] = useState<string>(selectedLang)
  const [locales, setLocales] = useState<string[]>(initialState.locales)
  const [owner, setOwner] = useState<number|null>(initialState.owner)
  const {data: companyData} = useQuery(QUERIES.COMPANY_DETAILS, getCompanyDetails,
    {cacheTime: 1000*60*5, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const setLanguage = (lang:string) => {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
    setLocale(lang)
    console.log('ls', lang)
  }

  useEffect(() => {
    if(owner && companyData) {
      if (!langIsSet()) {
        setLanguage(companyData.mainLocale)
      }
      setLocales(companyData.enabledLocales)
    }
  }, [owner, companyData])
  return <I18nContext.Provider value={{selectedLang: locale, setLanguage, locales, owner, setOwner}}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useI18n}
