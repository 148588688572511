import {toAbsoluteUrl} from "./AssetHelpers";

const isoToCountry = {
  "AF": "afghanistan",
  "AX": "aland-islands",
  "AL": "albania",
  "DZ": "algeria",
  "AS": "american-samoa",
  "AD": "andorra",
  "AO": "angola",
  "AI": "anguilla",
  "AG": "antigua-and-barbuda",
  "AR": "argentina",
  "AM": "armenia",
  "AW": "aruba",
  "AU": "australia",
  "AT": "austria",
  "AZ": "azerbaijan",
  "PT": "portugal",
  "BS": "bahamas",
  "BH": "bahrain",
  "ES": "spain",
  "BD": "bangladesh",
  "BB": "barbados",
  "BY": "belarus",
  "BE": "belgium",
  "BZ": "belize",
  "BJ": "benin",
  "BM": "bermuda",
  "BT": "bhutan",
  "BO": "bolivia",
  "BQ": "bonaire",
  "BA": "bosnia-and-herzegovina",
  "BW": "botswana",
  "BR": "brazil",
  "CA": "canada",
  "IO": "british-indian-ocean-territory",
  "VG": "british-virgin-islands",
  "BN": "brunei",
  "BG": "bulgaria",
  "BF": "burkina-faso",
  "BI": "burundi",
  "KH": "cambodia",
  "CM": "cameroon",
  "CV": "cape-verde",
  "KY": "cayman-islands",
  "CF": "central-african-republic",
  "TD": "chad",
  "CL": "chile",
  "CN": "china",
  "CX": "christmas-island",
  "CC": "cocos-island",
  "CO": "colombia",
  "KM": "comoros",
  "CK": "cook-islands",
  "FR": "france",
  "CR": "costa-rica",
  "HR": "croatia",
  "CU": "cuba",
  "CW": "curacao",
  "CZ": "czech-republic",
  "CD": "democratic-republic-of-congo",
  "DK": "denmark",
  "DJ": "djibouti",
  "DM": "dominica",
  "DO": "dominican-republic",
  "TL": "east-timor",
  "EC": "ecuador",
  "EG": "egypt",
  "SV": "el-salvador",
  "GB": "wales",
  "GQ": "equatorial-guinea",
  "ER": "eritrea",
  "EE": "estonia",
  "ET": "ethiopia",
  "EU": "european-union",
  "FK": "falkland-islands",
  "FJ": "fiji",
  "FI": "finland",
  "PF": "french-polynesia",
  "GA": "gabon",
  "GM": "gambia",
  "GE": "georgia",
  "DE": "germany",
  "GH": "ghana",
  "GI": "gibraltar",
  "GR": "greece",
  "GL": "greenland",
  "GD": "grenada",
  "GU": "guam",
  "GT": "guatemala",
  "GG": "guernsey",
  "GN": "guinea",
  "GW": "guinea-bissau",
  "HT": "haiti",
  "US": "united-states",
  "HN": "honduras",
  "HK": "hong-kong",
  "HU": "hungary",
  "IS": "iceland",
  "IN": "india",
  "ID": "indonesia",
  "IR": "iran",
  "IQ": "iraq",
  "IE": "ireland",
  "IM": "isle-of-man",
  "IL": "israel",
  "IT": "italy",
  "CI": "ivory-coast",
  "JM": "jamaica",
  "JP": "japan",
  "JE": "jersey",
  "JO": "jordan",
  "KZ": "kazakhstan",
  "KE": "kenya",
  "KI": "kiribati",
  "XK": "kosovo",
  "KW": "kuwait",
  "KG": "kyrgyzstan",
  "LA": "laos",
  "LV": "latvia",
  "LB": "lebanon",
  "LS": "lesotho",
  "LR": "liberia",
  "LY": "libya",
  "LI": "liechtenstein",
  "LT": "lithuania",
  "LU": "luxembourg",
  "MO": "macao",
  "MG": "madagascar",
  "MW": "malawi",
  "MY": "malaysia",
  "MV": "maldives",
  "ML": "mali",
  "MT": "malta",
  "MH": "marshall-island",
  "MR": "mauritania",
  "MU": "mauritius",
  "MX": "mexico",
  "FM": "micronesia",
  "MD": "moldova",
  "MC": "monaco",
  "MN": "mongolia",
  "ME": "montenegro",
  "MA": "morocco",
  "MZ": "mozambique",
  "MM": "myanmar",
  "NA": "namibia",
  "NP": "nepal",
  "NL": "netherlands",
  "NZ": "new-zealand",
  "NI": "nicaragua",
  "NE": "niger",
  "NG": "nigeria",
  "NF": "norfolk-island",
  "KP": "north-korea",
  "NO": "norway",
  "OM": "oman",
  "PK": "pakistan",
  "PW": "palau",
  "PS": "palestine",
  "PA": "panama",
  "PY": "paraguay",
  "PE": "peru",
  "PH": "philippines",
  "PL": "poland",
  "PR": "puerto-rico",
  "QA": "qatar",
  "RO": "romania",
  "RU": "russia",
  "RW": "rwanda",
  "SA": "saudi-arabia",
  "SN": "senegal",
  "RS": "serbia",
  "SG": "singapore",
  "SK": "slovakia",
  "SI": "slovenia",
  "ZA": "south-africa",
  "KR": "south-korea",
  "LK": "sri-lanka",
  "SD": "sudan",
  "SE": "sweden",
  "CH": "switzerland",
  "SY": "syria",
  "TW": "taiwan",
  "TJ": "tajikistan",
  "TZ": "tanzania",
  "TH": "thailand",
  "TG": "togo",
  "TT": "trinidad-and-tobago",
  "TN": "tunisia",
  "TR": "turkey",
  "TM": "turkmenistan",
  "UG": "uganda",
  "UA": "ukraine",
  "AE": "united-arab-emirates",
  "UY": "uruguay",
  "UZ": "uzbekistan",
  "VA": "vatican-city",
  "VE": "venezuela",
  "VN": "vietnam",
  "YE": "yemen",
  "ZM": "zambia",
  "ZW": "zimbabwe"
};
const isoToFlag = (iso:string|null|undefined) => {
  let flag = 'default';
  if(iso && iso in isoToCountry) {
    // @ts-ignore
    flag = isoToCountry[iso];
  }
  return toAbsoluteUrl(`/media/flags/${flag}.svg`)
}

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: isoToFlag('US'),
  },
  {
    lang: 'uk',
    name: 'Українська',
    flag: isoToFlag('UA'),
  },
  {
    lang: 'ru',
    name: 'Русский',
    flag: isoToFlag('RU'),
  },
  // {
  //   lang: 'zh',
  //   name: 'Mandarin',
  //   flag: toAbsoluteUrl('/media/flags/china.svg'),
  // },
  // {
  //   lang: 'es',
  //   name: 'Spanish',
  //   flag: toAbsoluteUrl('/media/flags/spain.svg'),
  // },
  // {
  //   lang: 'ja',
  //   name: 'Japanese',
  //   flag: toAbsoluteUrl('/media/flags/japan.svg'),
  // },
  // {
  //   lang: 'de',
  //   name: 'German',
  //   flag: toAbsoluteUrl('/media/flags/germany.svg'),
  // },
  // {
  //   lang: 'fr',
  //   name: 'French',
  //   flag: toAbsoluteUrl('/media/flags/france.svg'),
  // },
  {
    lang: 'bs',
    name: 'Bosanski',
    flag: isoToFlag('BA'),
  },
]

export {languages, isoToFlag}
