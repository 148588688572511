import {Form, Formik, FormikValues} from 'formik'
import React, {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {usePageView} from '../../../core/PageViewProvider'
import {IStatus, IStatusForm} from '../../../core/_models'
import {updateStatus} from '../../../core/_requests'
import {CheckboxSwitcher} from '../../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'

interface Props {
  status: IStatus
  invalidateStatus: () => void
}
const StatusOverviewTab = ({status, invalidateStatus}: Props) => {
  const {locale = 'uk'} = useParams()

  const initialValues: Partial<IStatusForm> = {
    name: status?.translations[locale]?.name || '',
    active: status.showClient === true ? 1 : 0,
    description: status?.translations[locale]?.description || '',
  }
  console.log(initialValues)

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<IStatusForm>, actions: FormikValues) => {
    setLoading(true)

    try {
      const {active, name, description} = values
      const dataToSend: Partial<IStatus> = {
        value: status.value,
        translations: {
          ...status.translations,
          [locale]: {
            name: name || status.translations[locale].name,
            description: description || status.translations[locale].description,
          },
        },
        showClient: active === 1 ? true : false,
      }

      await updateStatus(dataToSend)

      invalidateStatus()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
      actions.resetForm()
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='form-overview-blog'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <FormattedMessage id="GENERAL.LABELS.NAME" />
                </label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='name' required={true} type='text' />
                </div>
              </div>{' '}
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <FormattedMessage id="GENERAL.STATUSES.ACTIVE" />
                </label>

                <div className='col-lg-8'>
                  <CheckboxSwitcher
                    inputName='active'
                    firstOption='Заблокувати'
                    secondOption='Активувати'
                    // @ts-ignore
                    value={+values.active}
                    setFieldValue={setFieldValue}
                    bgColor={{first: 'var(--kt-success)', second: 'var(--kt-danger)'}}
                  />
                </div>
              </div>{' '}
              <div className='row align-items-center'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <FormattedMessage id="GENERAL.LABELS.DESCRIPTION" defaultMessage="Опис" />
                </label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='description' type='textarea'/>
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default StatusOverviewTab
