import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {QUERIES} from '../../../../../_metronic/helpers'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../InputTemplate'
import {useQueryClient} from "react-query";
import {IWebsite} from "../../../../pages/websites/core/_models";

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

const WebsitesSelect = ({withTitle = true, disabled = false, multiple = false}) => {
  const intl = useIntl()
  const {values, setFieldValue} = useFormikContext<{
    website?: IBasicSelect
    websites?: IBasicSelect[]
  }>()

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const filterWebsites = useCallback((query:string)=> {
    setResultsLoading(true)
    const reformed = websitesData?.map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id,
      }
    }).filter((item)=>item.label.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    setResults(reformed||[])
    setResultsLoading(false)
    }, [websitesData]
  )
  useEffect(() => {
    filterWebsites('')
  }, [])

  const selectOptions = multiple?{
    isMultiple: true,
    currentValue: values.websites || [],
    selectName: 'websites',
  }:{
    currentValue: values.website || {value: '', label: ''},
    selectName: 'website',
  };

  return (
    <InputTemplate
      inputName='website'
      required={true}
      type='select'
      // value={values.website || {value: '', label: ''}}
      title={withTitle ? intl.formatMessage({id: 'COMPONENTS.WebsitesSelect.LABEL'}) : ''}
      titleFontSize='fs-5 text-dark'
      disabled={disabled}
      reactSelectprops={{
        ...selectOptions,
        options: results,
        optionFormat: ({faviconUrl, name, host}) => (
          <div className='tagify__dropdown__item d-flex align-items-center'>
            <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer'>
              <img
                alt=''
                className='rounded-circle w-25px me-2'
                src={
                  faviconUrl
                    ? `${BASE_URL}${faviconUrl}`
                    : '/media/icons/duotune/communication/com001.svg'
                }
              />
            </div>

            <div className='d-flex flex-column text-gray-600'>
              <strong>{name}</strong>
              {host && <span className='fs-6 fw-normal'>{host}</span>}
            </div>
          </div>
        ),
        isLoading: resultsLoading,
        onInputChange: filterWebsites,
        setFieldValue: setFieldValue,
        setObject: true,
        placeholder: intl.formatMessage({
          id: 'STORES.ADD_MODAL.FORM.WEBSITES_PLACEHOLDER',
        }),
        noOptionsMessage: intl.formatMessage({
          id: 'STORES.ADD_MODAL.FORM.NO_WEBSITES_MESSAGE',
        }),
      }}
    />
  )
}

export default WebsitesSelect
