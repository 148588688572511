import {useEffect} from 'react'
import {useQueries, useQuery} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {getCountries} from '../countries/countries-list/core/_requests'
// import DeliveryTypePage from './direction-page/DeliveryTypePage'
import {DeliveryTypesListWrapper} from './delivery-types-list/DeliveryTypesList'
import DeliveryTypePage from './delivery-type-page/DeliveryTypePage'
import {generalApi} from '../../../_metronic/helpers/custom/api/generalApi'
import {directionsApi} from '../directions/core/_requests'
import {deliveryRateApi} from '../../../_metronic/helpers/custom/api/deliveryRateApi'
import {deliveryTypesApi} from './core/_requests'
import {getCategories} from "../lists/categories/core/_requests";

const DeliveryTypesPage = () => {
  const location = useLocation()

  useQueries([
    {
      queryKey: [QUERIES.COUNTRIES_LIST],
      queryFn: () => getCountries(''),
    },
    {
      queryKey: [QUERIES.COUNTRY_ABBR_LIST],
      queryFn: directionsApi.fetchDirectionCountriesList,
    },
    {queryKey: ['priceRangeTypes'], queryFn: deliveryRateApi.getDeliveryPriceRangeTypes},
    {
      queryKey: [QUERIES.CURRENCIES_LIST],
      queryFn: generalApi.getListOfCurrencies,
    },
    {queryKey: [QUERIES.WEIGHT_UNITS], queryFn: generalApi.getWeightUnits},
    {queryKey: [QUERIES.SIZE_UNITS], queryFn: generalApi.getSizeUnits},
    {
      queryKey: [QUERIES.VOL_WEIGHT_OPTIONS],
      queryFn: deliveryTypesApi.fetchVolumetricWeightOptions,
    },
    {
      queryKey: [QUERIES.VOL_WEIGHT_PRICE_METHODS],
      queryFn: deliveryTypesApi.fetchVolumetricWeightPriceMethods,
    },
  ])

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DeliveryTypesListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <DeliveryTypePage
              id={location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/delivery-types/list' />} />
    </Routes>
  )
}

export default DeliveryTypesPage
