const QUERIES = {
  ARCHIVED_TRACKS_LIST: 'archived-tracks-list',
  BLOG_ARTICLES_LIST: 'blog-articles-list',
  BUYOUTS_LIST: 'buyouts-list',
  CATEGORIES_LIST: 'categories-list',
  CHATS_LIST: 'chats-list',
  CHATS_STATUSES_LIST: 'chats-statuses-list',
  CLIENT_TRANSLATION: 'client-translation',
  COUNTRIES_LIST: 'countries-list',
  COUNTRY_ABBR_LIST: 'country-abbr-list',
  COURIERS_LIST: 'couriers-list',
  COURIER_TYPES: 'courier-types',
  DELIVERY_TYPES_LIST: 'delivery-types-list',
  DIRECTIONS_LIST: 'directions-list',
  DIRECTIONS_WITH_IDS_LIST: 'directionsWithIds',
  EMAIL_TEMPLATES_LIST: 'email-templates-list',
  EMPLOYEES_LIST: 'employees-list',
  FULF_RECEIVERS_LIST: 'fulf-receivers-list',
  PHONE_CODES_LIST: 'phone-codes-list',
  LOCALES_LIST: 'locales-list',
  LOCATIONS_LIST: 'locations-list',
  ORDERS_LIST: 'orders-list',
  PARCELS_LIST: 'parcels-list',
  PAYMENT_STATUSES: 'payment-statuses',
  PAYMENTS_LIST: 'payments-list',
  PAYMENT_PROVIDERS_LIST: 'payment-providers-list',
  PAYMENT_PROVIDER_TYPES_LIST: 'payment-provider-types-list',
  POLLS_LIST: 'polls-list',
  POLL_TYPES_LIST: 'poll-types-list',
  PRODUCTS_LIST: 'fulfillment-products-list',
  PROFILE_ADDRESSES_LIST: 'profile-addresses-list',
  RECEIVER_ADDRESSES: 'receiver-addresses-list',
  RECEIVER_HISTORY: 'receiver-history',
  RECEIVERS_LIST: 'receivers-list',
  RECENT_INVOICES: 'recent-invoices-list',
  REGIONS_LIST: 'regions-list',
  SHIPMENTS_LIST: 'shipments-list',
  STATUSES_LIST: 'statuses-list',
  STORES_LIST: 'stores-list',
  TARIFFS_LIST: 'tariffs-list',
  TRACKS_ACCEPT_LIST: 'accept-tracks-list',
  TRACKS_LIST: 'tracks-list',
  TRANSLATIONS_CHAT_LIST: 'chat-tr-list',
  TRANSLATIONS_ADMIN_LIST: 'admin-tr-list',
  TRANSLATIONS_CLIENT_LIST: 'client-tr-list',
  TRANSLATIONS_TELEGRAM_LIST: 'telegram-tr-list',
  USER_GROUPS_LIST: 'user-groups-list',
  USER_HISTORY: 'user-history',
  USERS_LIST: 'users-list',
  WAREHOUSES_LIST: 'warehouses-list',
  WEBSITE_CATEGORIES_LIST: 'website-categories-list',
  WEBSITE_COLLECTIONS_LIST: 'website-collections-list',
  WEBSITES_LIST: 'websites-list',
  // BY ID
  COMPANY_DETAILS: 'company-details',
  COUNTRY_BY_ID: 'country-by-id',
  COURIER_BY_ID: 'courier-by-id',
  DELIVERY_TYPE_BY_ID: 'delivery-type-by-id',
  DIRECTION_BY_ID: 'direction-by-id',
  LOCATION_BY_ID: 'location-by-id', //city
  ORDER_BY_ID: 'order-by-id',
  PARCEL_BY_ID: 'parcel-by-id',
  PAYMENT_BY_ID: 'payment-by-id',
  PAYMENT_PROVIDER_BY_ID: 'payment-provider-by-id',
  PROVIDER_BY_ID: 'provider-by-id',
  POLL_BY_ID: 'poll-by-id',
  POLL_RESULTS_BY_ID: 'poll-results-by-id',
  RECEIVER_BY_ID: 'receiver-by-id',
  REGION_BY_ID: 'region-by-id',
  SHIPMENT_BY_ID: 'shipment-by-id',
  TRACK_BY_ID: 'track-by-id',
  TELEGRAM_TR_BY_ID: 'chat-translation',
  USER_BY_ID: 'user-by-id',
  WAREHOUSE_BY_ID: 'warehouse-by-id',
  WEBSITE_BY_ID: 'website-by-id',
  WEBSITE_PAGE_BY_ID: 'website-page-by-id',
  // UNITS
  CURRENCIES_LIST: 'currencies-list',
  SIZE_UNITS: 'sizeUnits',
  VOL_WEIGHT_OPTIONS: 'volumetricWeightOptions',
  VOL_WEIGHT_PRICE_METHODS: 'volumetricWeightPriceMethods',
  WEIGHT_UNITS: 'weightUnits',
}
const LS_KEYS = {
  PAGINATION_KEY: 'tablePagination',
}

const TABLE_LIST_FILTER_KEY = (listName: string) => {
  return `${listName}-filter`
}

const FULF_RECEIVER_VERIFICATION_STATUS = {
  VERIFIED: 'Verified', // success
  CHECKED: 'Checked', // danger
  WAITING: 'Waiting', // warning
}

const CARDFOOTER_NOTIFICATIONS = (msg?: string) => {
  return {
    SUCCESSFULLY_SAVED: {
      success: true,
      color: 'success',
      intl: 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING',
      icon: '/media/icons/duotune/general/gen043.svg',
    },
    ERROR: {
      success: false,
      color: 'danger',
      intl: msg || 'ERROR',
      icon: '/media/icons/duotune/general/gen040.svg',
    },
  }
}

export {
  QUERIES,
  LS_KEYS,
  FULF_RECEIVER_VERIFICATION_STATUS,
  CARDFOOTER_NOTIFICATIONS,
  TABLE_LIST_FILTER_KEY,
}
