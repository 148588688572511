import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IStatus} from '../../core/_models'
import StatusHeaderLangSwitcher from './StatusHeaderLangSwitcher'
import {StatusHeaderName} from './StatusHeaderName'
import {StatusHeaderNav} from './StatusHeaderNav'

interface Props {
  status: IStatus
}

const StatusHeader = ({status}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed fit-content position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/graphs/gra006.svg')}
                  alt='Template'
                  style={{objectFit: 'cover'}}
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <StatusHeaderName status={status} />
            </div>
          </div>
          <StatusHeaderLangSwitcher status={status} />
        </div>

        <StatusHeaderNav />
      </div>
    </div>
  )
}

export {StatusHeader}
