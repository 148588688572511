/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItem} from './AsideMenuItem'
import {useQueries, useQuery} from 'react-query'
import {websitesApi} from '../../../../app/pages/websites/core/_requests'
import {QUERIES} from '../../../helpers'
import WebsitesMenu from './categories/WebsitesMenu'
import PaymentsMenu from './categories/PaymentsMenu'
import FulfillmentMenu from './categories/FulfillmentMenu'
import TranslationsMenu from './categories/TranslationsMenu'
import UsersMenu from './categories/UsersMenu'
import LogisticsMenu from './categories/LogisticsMenu'
import TracksMenu from './categories/TracksMenu'
import {directionsApi} from '../../../../app/pages/directions/core/_requests'
import ListsMenu from './categories/ListsMenu'
import ChatsMenu from './categories/ChatsMenu'
import BuyoutsMenu from './categories/BuyoutsMenu'
import FinancesMenu from './categories/FinancesMenu'

export function AsideMenuMain() {
  useQuery(QUERIES.COUNTRY_ABBR_LIST, directionsApi.fetchDirectionCountriesList,
    {cacheTime: 1000*60*5, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  useQuery([QUERIES.DIRECTIONS_WITH_IDS_LIST], () => directionsApi.fetchDirectionsWithIds(''))
  useQueries([{queryKey: [QUERIES.WEBSITES_LIST], queryFn: websitesApi.fetchWebsites}])

  return (
    <>
      {menuItems.map((menuItem) => (
        <AsideMenuItem
          key={menuItem.id}
          to={menuItem.to}
          icon={menuItem.icon}
          title={menuItem.title}
          fontIcon={menuItem.fontIcon}
        />
      ))}
      <BuyoutsMenu />
      <TracksMenu />
      <FinancesMenu />
      <LogisticsMenu />
      <UsersMenu />
      <TranslationsMenu />
      <WebsitesMenu />
      <ChatsMenu />
      <ListsMenu />
      <FulfillmentMenu />
      {/* <PaymentsMenu /> */}
    </>
  )
}
const menuItems = [
  {
    id: 'dashboard',
    to: '/dashboard',
    title: 'GENERAL.ASIDE_MENU.DASHBOARD_TITLE',
    icon: '/media/icons/duotune/art/art002.svg',
    fontIcon: 'bi-app-indicator',
  },
  {
    id: 'email-templates',
    to: '/email-templates',
    title: 'GENERAL.ASIDE_MENU.EMAIL_TEMPLATES_TITLE',
    icon: '/media/icons/duotune/communication/com002.svg',
    fontIcon: 'bi-layers',
  },
  {
    id: 'polls',
    to: '/polls',
    title: 'GENERAL.ASIDE_MENU.POLLS_TITLE',
    icon: '/media/icons/duotune/files/fil008.svg',
    fontIcon: 'bi-layers',
  },
  {
    id: 'settings',
    to: '/settings/edit/overview',
    title: 'GENERAL.ASIDE_MENU.SETTINGS_TITLE',
    icon: '/media/icons/duotune/coding/cod001.svg',
    fontIcon: 'bi-layers',
  },
]
