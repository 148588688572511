import {FormattedMessage} from 'react-intl'
import {AsideMenuItem} from '../AsideMenuItem'

const TranslationsMenu = () => {
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-gray-300 fw-bold text-uppercase fs-8 ls-1'>
            <FormattedMessage id='GENERAL.ASIDE_MENU.TRANSLATIONS_SECTION' />
          </span>
        </div>
      </div>

      {menuItems.map((item, index) => (
        <AsideMenuItem
          key={index}
          to={item.to}
          icon={item.icon}
          title={item.title}
          fontIcon={item.fontIcon}
        />
      ))}
    </>
  )
}

const menuItems = [
  {
    to: '/translations/company/list',
    icon: '/media/icons/duotune/maps/map004.svg',
    title: 'GENERAL.ASIDE_MENU.TRANSLATIONS_COMPANY_TITLE',
    fontIcon: 'bi-layers'
  },
  {
    to: '/translations/client/list',
    icon: '/media/icons/duotune/communication/com005.svg',
    title: 'GENERAL.ASIDE_MENU.TRANSLATIONS_CLIENT_TITLE',
    fontIcon: 'bi-layers',
  },
  {
    to: '/translations/chat/list',
    icon: '/media/icons/duotune/communication/com007.svg',
    title: 'GENERAL.ASIDE_MENU.TRANSLATIONS_CHATS_TITLE',
    fontIcon: 'bi-layers',
  },
  {
    to: '/translations/telegram/list',
    icon: '/media/custom/svg/telegram-app.svg',
    title: 'GENERAL.ASIDE_MENU.TRANSLATIONS_TELEGRAM_TITLE',
    fontIcon: 'bi-layers',
  },
]

export default TranslationsMenu
