import {useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {IStatus} from '../../core/_models'
import {useQueryClient} from "react-query";
import {QUERIES} from "../../../../../../_metronic/helpers";
import {ICompanyDetails} from "../../../../settings/core/_models";

interface Props {
  status: IStatus
}

const StatusHeaderLangSwitcher = ({status}: Props) => {
  const {locale} = useParams()
  const [activeTab, setActiveTab] = useState(locale)
  const navigate = useNavigate()

  const location = useLocation()
  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  const queryClient = useQueryClient()
  const company: ICompanyDetails | undefined = queryClient.getQueryData(QUERIES.COMPANY_DETAILS)

  return (
    <ul className='nav'>
      {company?.enabledLocales.map((locale) => (
        <li className='nav-item' key={locale}>
          <button
            type='button'
            onClick={() => {
              navigate(`/lists/statuses/edit/${currTab}/${locale}/${status.value.toLowerCase()}`)

              setActiveTab(locale)
            }}
            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
              activeTab === locale ? 'active' : ''
            } fw-bold px-4`}
          >
            {locale}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default StatusHeaderLangSwitcher
