/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {IStatus} from '../../core/_models'
import {useQueryClient} from "react-query";

type Props = {
  status: IStatus
}

const ActionsCell: FC<Props> = ({status}) => {
  const intl = useIntl()

  const queryClient = useQueryClient()
  const company: any = queryClient.getQueryData(QUERIES.COMPANY_DETAILS)
  const defaultLocale = company.mainLocale || 'uk'

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
        >
          <Link
            to={`/lists/statuses/edit/overview/${defaultLocale}/${status.value.toLowerCase()}`}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </Link>
        </OverlayTrigger>
      </div>
    </>
  )
}

export {ActionsCell}
