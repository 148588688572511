import axios from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`

export function getDashboardTranslationData() {
  const {selectedLang} = getConfig()
  return axios.get(`${BASE_URL}/company/dashboard_translations`, {
    headers: {'Accept-Language': selectedLang},
  })
}
