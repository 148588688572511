/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {ITranslationObject} from '../../../../../../websites/core/_models'

type Props = {
  translation: ITranslationObject
}

const TranslationActionsCell: FC<Props> = ({translation}: Props) => {
  const intl = useIntl()
  const availableLangs = translation ? translation.translations.map((i) => i.locale) : []

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }

  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
      >
        <Link
          to={`../edit/${translation.id}/overview/${availableLangs[0]}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </OverlayTrigger>
    </div>
  )
}

export {TranslationActionsCell}
