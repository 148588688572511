import {Route, Routes} from 'react-router-dom'
import ChatTranslationsPage from './chat/ChatTranslationsPage'
import {TranslationsPage as GeneralTranslationsPage} from './general/TranslationsPage'
import TelegramTranslationsPage from './telegram/TelegramTranslationsPage'
import {QUERIES, toAbsoluteUrl} from "../../../_metronic/helpers";

const TranslationsPage = () => {
  return (
    <Routes>
      <Route path='company/*' element={
        <GeneralTranslationsPage
          route={'company_dashboard_translation_keys'}
          name={'GENERAL.ASIDE_MENU.TRANSLATIONS_COMPANY_TITLE'}
          queryN={QUERIES.TRANSLATIONS_ADMIN_LIST}
          sync={'company/dashboard_translations_update'}
          warning={true}
          icon={toAbsoluteUrl('/media/icons/duotune/maps/map004.svg')}
        />}
      />
      <Route path='client/*' element={
        <GeneralTranslationsPage
          route={'company_translation_keys'}
          name={'GENERAL.ASIDE_MENU.TRANSLATIONS_CLIENT_TITLE'}
          queryN={QUERIES.TRANSLATIONS_CLIENT_LIST}
          sync={'company/translations_update'}
          warning={true}
        />}
      />
      <Route path='chat/*' element={<ChatTranslationsPage />} />
      <Route path='telegram/*' element={<TelegramTranslationsPage />} />

      {/* <Route path='company' element={< />} /> */}
    </Routes>
  )
}

export default TranslationsPage
