/* eslint-disable jsx-a11y/anchor-is-valid */
import {IOrderProps} from '../../../../core/_models/_orders-models'
import {FormattedDate, FormattedTime} from "react-intl";

const OrderStatusCell = ({order}: IOrderProps) => {
  return (
    <div className='text-center'>
      {order.status &&
        <div className='d-flex flex-column'>
          <span className='text-dark fw-bold fs-6'>
            {order.status?.name}
          </span>
          <span className='text-dark fw-bold fs-6'>
            <FormattedDate year='numeric' month='numeric' day='numeric' value={order.status?.createdAt}/>
          </span>
          <span className='text-muted fw-normal fs-6'>
            <FormattedTime
                value={order.status.createdAt}
                hour='numeric'
                minute='numeric'
                second='numeric'
            />
          </span>
        </div>
      }
    </div>
  )
}

export {OrderStatusCell}
