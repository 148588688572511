import { useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {IWebsite, ITranslationObject} from '../../../../websites/core/_models'
import {useQueryClient} from "react-query";
import {QUERIES} from "../../../../../../_metronic/helpers";
import {ICompanyDetails} from "../../../../settings/core/_models";
import {useTranslation} from "../../TranslationsPage";

interface Props {
  translation: ITranslationObject
}

const TranslationHeaderLangSwitcher = ({translation}: Props) => {
  const {locale} = useParams()

  const {defaultLocale, langs} = useTranslation()
  let curLocale = locale?locale:defaultLocale
  const [activeTab, setActiveTab] = useState(curLocale)

  return (
    <ul className='nav flex-nowrap'>
      {translation &&
        langs?.map((locale) => (
          <li className='nav-item' key={locale}>
            <Link
              to={`../overview/${locale}`}
              onClick={() => {
                setActiveTab(locale)
              }}
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                activeTab === locale ? 'active' : ''
              } fw-bold px-4`}
            >
              {locale}
            </Link>
          </li>
        ))}
    </ul>
  )
}

export default TranslationHeaderLangSwitcher
