import {useEffect, useState} from 'react'
import {Formik, Form, FormikHelpers, Field} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {useListView} from '../core/ListViewProvider'

import {ScrollTopComponent} from '../../../../_metronic/assets/ts/components'
import {CreateStoreModalHeader} from './CreateStoreModalHeader'
import {createStoreSchema} from '../core/yup/store'
import {IStore, ICreateStoreForm} from '../core/_models'
import {addStore} from '../core/_requests'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {IWebsite} from '../../websites/core/_models'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import ScrollToFieldError from './ScrollToFieldError'
import {KTSVG, QUERIES} from '../../../../_metronic/helpers'
import WebsitesSelect from '../../../modules/custom/form-elements/selects/WebsitesSelect'
import CategoriesMultipleSelect from '../../../modules/custom/form-elements/selects/CategorySelect'
import CountriesSelect from "../../../modules/custom/form-elements/selects/CountriesSelect";
import {ICompanyDetails} from "../../settings/core/_models";

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

const CreateStoreForm = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const companyData: ICompanyDetails | undefined = queryClient.getQueryData(QUERIES.COMPANY_DETAILS)
  const enabledLocales = companyData ? companyData.enabledLocales : []
  const {setItemIdForUpdate} = useListView()
  const {selectedLang} = getConfig()

  const {refetch} = useQueryResponse()

  const inits: ICreateStoreForm = {
    name: '',
    companyName: '',
    companyTaxCode: '',
    slug: '',
    urls: [''],
    websites: [],
    shopCollection: {value: '', label: ''},
    link: '',
    categories: [],
    countries: [],
    // @ts-ignore
    categoriesList: [],
    translations: {
      [selectedLang]: {
        title: '',
        shortDescription: '',
        description: '',
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
      },
    },
  }

  const [initValues] = useState<ICreateStoreForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  //mutation
  const createStoreMutation = useMutation(addStore, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      setItemIdForUpdate(undefined)

      ScrollTopComponent.goTop()
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (
    values: ICreateStoreForm,
    formikHelpers: FormikHelpers<ICreateStoreForm>
  ) => {
    let translations = {
      [selectedLang]: {
        title: values.name,
        shortDescription: '',
        description: '',
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
      },
    }
    if (enabledLocales.filter((lang)=>lang!==selectedLang)) {
      enabledLocales.forEach((locale) => {
        translations[locale] = {
          title: values.name,
          shortDescription: '',
          description: '',
          metaTitle: '',
          metaDescription: '',
          metaKeywords: '',
        }
      })
    }
    createStoreMutation.mutate({
      ...values,
      websites: values.websites.map((item) => {
        return {id: item.value}
      }),
      shopCollection: values.shopCollection?.value?{id: values.shopCollection?.value}:null,
      translations,
      categories: values.categories.map((item) => {
        return {id: item.value}
      }),
      countries: values?.countries?.map((i)=>{
        return {id: i.value}
      }),
    })
    formikHelpers.resetForm()
  }

  useEffect(() => {
    document.body.style.overflow = ''
  }, [])

  return (
    <Formik
      validationSchema={createStoreSchema(intl)}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form h-100' noValidate id='kt_modal_create_store_form'>
            <ScrollToFieldError />

            <CreateStoreModalHeader isLoading={isLoading} isValid={isValid} />
            <div
              className='scroll-y modal-body p-0 h-100'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='p-3 text-primary'>
                {/* {JSON.stringify(errors)}
                {JSON.stringify(values)} */}
                <div className='w-100'>
                  <div className='fv-row mb-5'>
                    <WebsitesSelect />
                  </div>
                  <div className='fv-row mb-5'>
                    <p className='fw-bolder fs-5 text-dark required mb-3'>Категорії</p>
                    {values.categoriesList && values.categoriesList.length > 0 && (
                      <CategoriesMultipleSelect
                        categoriesAvailable={values.categoriesList.map((i) => ({
                          image: i.imageUrl ? `${BASE_URL}${i.imageUrl}` : '/media',
                          label: i.name,
                          value: i.id,
                        }))}
                        values={values}
                      />
                    )}
                  </div>
                  <div className='fv-row mb-5'>
                    <p className='fw-bolder fs-5 text-dark required mb-3'>Країни</p>
                    <CountriesSelect
                      inputName={'countries'}
                      queryFilter='filter[send]=true'
                      withTitle={false}
                      isMulti={true}
                      isClearable={true}
                    />
                  </div>
                  <div className='fv-row mb-5'>
                    {' '}
                    <InputTemplate
                      inputName='name'
                      type='text'
                      title={intl.formatMessage({id: 'STORES.ADD_MODAL.FORM.TITLE_LABEL'})}
                      titleFontSize='fs-5 text-dark'
                      required={true}
                    />
                  </div>
                  <div className='fv-row'>
                    {' '}
                    <InputTemplate
                      inputName='slug'
                      type='text'
                      title={intl.formatMessage({id: 'STORES.ADD_MODAL.FORM.SLUG_LABEL'})}
                      titleFontSize='fs-5 text-dark'
                      handleChange={(string: string) => {
                        setFieldValue('slug', string.replaceAll(' ', ''))
                      }}
                      required={true}
                    />
                  </div>
                  <div className='fv-row'>
                    {' '}
                    <InputTemplate
                      inputName='link'
                      type='text'
                      title={'Посилання на магазин'}
                      titleFontSize='fs-5 text-dark'
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export {CreateStoreForm}
