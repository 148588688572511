import {Formik, Form} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {IDeliveryType, IDeliveryTypeForm} from '../../../core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {QUERIES, selectDebounce} from '../../../../../../_metronic/helpers'
import {deliveryTypesApi} from '../../../core/_requests'
import {ICategory, ICategorySelectOption} from '../../../../lists/categories/core/_models'
import CategoryCheckbox from '../../../../stores/_modals/CategoryCheckbox'
import CategoriesMultipleSelect from '../../../../../modules/custom/form-elements/selects/CategorySelect'
import {getCategories} from "../../../../lists/categories/core/_requests";

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

interface Props {
  deliveryType: IDeliveryType
  setCurrDeliveryType: (obj: IDeliveryType) => void
}

export function DeliveryTypeCategories({deliveryType, setCurrDeliveryType}: Props) {
  const initialValues: Partial<IDeliveryTypeForm> = {
    categories: deliveryType.productCategories.map((item) => ({
      value: item.id,
      label: item.name || '',
      image: item.logoUrl ? `${BASE_URL}${item.logoUrl}` : '/media',
    })),
  }

  const queryClient = useQueryClient()

  const [categoriesResults, setCategoriesResults] = useState<ICategorySelectOption[] | undefined>(
    []
  )

  const filterCategories = useCallback(
    selectDebounce((query: string, callback?: any) => {
      getCategories(`search=${query}`)
        .then((res) => {
          const reformedCategories = res.data?.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          // @ts-ignore
          setCategoriesResults(reformedCategories)
          if (callback) {
            callback(reformedCategories)
          }
        })
        .catch((err) => console.log(err))
    }, 500),
    [getCategories]
  )

  useEffect(() => {
    filterCategories('')
  }, [filterCategories])

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<IDeliveryTypeForm>) => {
    setLoading(true)

    const deliveryTypeData = {
      id: deliveryType.id,
      productCategories:
        values.categories && values.categories?.length > 0
          ? values.categories?.map((item) => {
              return {id: item.value}
            })
          : [],
    }
    const response = await deliveryTypesApi.editDeliveryType(deliveryTypeData)

    if (response) {
      queryClient.invalidateQueries([QUERIES.DELIVERY_TYPE_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        // validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Категорії</label>

                <div className='col-lg-8'>
                  {categoriesResults && categoriesResults.length > 0 && (
                    <CategoriesMultipleSelect
                      values={values}
                      categoriesAvailable={categoriesResults.map((i) => ({
                        value: i.id,
                        label: i.name,
                        image: i.logoUrl ? `${BASE_URL}${i.logoUrl}` : '/media',
                      }))}
                    />
                  )}
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
