import React, {createContext, useContext, useEffect} from 'react'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {LayoutSetup, useLayout} from '../../../../_metronic/layout/core'
import {TranslationsListWrapper} from './translations-list/TranslationsList'
import {PageViewProvider} from './core/PageViewProvider'
import GeneralTranslationPage from './translation-page/GeneralTranslationPage'
import {useQueryClient} from "react-query";
import {ICompanyDetails} from "../../settings/core/_models";
import {QUERIES} from "../../../../_metronic/helpers";
import {IWebsite} from "../../websites/core/_models";

interface Props {
  name: string
  route: string
  queryN: string
  sync?: string
  icon?: string
  editor?: boolean
  notes?: boolean
  warning?: boolean
  website?: IWebsite
  langs?: string[]
  defaultLocale?: string
}
const initialState = {
  name: 'GENERAL.ASIDE_MENU.TRANSLATIONS_SECTION',
  route: '',
  queryN: '',
  langs: []
}
const TranslationsContext = createContext<Props>(initialState)
const useTranslation = () => {
  return useContext(TranslationsContext)
}

const TranslationsPage = (params:Props) => {
  const {config} = useLayout()
  const queryClient = useQueryClient()
  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])
  let langs = []
  let defaultLocale = ''
  if(!params.website) {
    const companyData: ICompanyDetails | undefined = queryClient.getQueryData(QUERIES.COMPANY_DETAILS)
    langs = companyData ? companyData.enabledLocales : []
    defaultLocale = companyData ? companyData.mainLocale : 'uk'
  }else{
    langs = params.website.enabledLocales
    defaultLocale = params.website.mainLocale
  }

  return (
    <TranslationsContext.Provider value={{...params, langs, defaultLocale}}>
      <Routes>
        <Route element={<Outlet />}>
          <Route path='list' element={<TranslationsListWrapper />} />

          <Route
            path={`edit/:id/*`}
            element={
              <PageViewProvider>
                <GeneralTranslationPage />
              </PageViewProvider>
            }
          />
          <Route path='*' element={<Navigate to='list' />} />
        </Route>
      </Routes>
    </TranslationsContext.Provider>
  )
}

export {TranslationsPage, useTranslation}
